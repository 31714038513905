import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Login from './components/Login';
import CoverLetter from './components/CoverLetter';
import './App.css';
import { UserProvider } from './contexts/UserContext';
import InterviewQuestions from './components/InterviewQuestions';
import SettingsPage from './components/Settings';
import Footer from './components/Footer'
import AboutUs from './components/AboutUs';
import { Navigate } from 'react-router-dom';

const App = () => {
  return (
    <UserProvider>
    <div className="appContainer">
      <Router>
        <Navbar />
        <div className="app-content">
          <Routes>
            <Route path="/" element={<CoverLetter />} />
            <Route path="/interview-questions" element={<InterviewQuestions />} />
            {/* <Route path="/resume-assistant" element={<ResumeAssistant />} /> */}
            <Route path="/login" element={<Login />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/about" element={<AboutUs />} />
            {/* Wildcard route to redirect to home */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <Footer /> {/* Add Footer here, after Routes */}
        </Router>
    </div>
    </UserProvider>
  );
};

export default App;
