import React, { useEffect, useState } from 'react';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { storage, auth } from "../firebase";
import { FaFilePdf } from 'react-icons/fa';
import './FileHandler.css';

const FileHandler = ({ onResumeChange }) => {
  const [userId, setUserId] = useState(null);
  const [resumeUrl, setResumeUrl] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        fetchResume(user.uid); // Fetch resume when user logs in
      } else {
        setUserId(null);
        setResumeUrl(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchResume = async (userId) => {
    try {
      const resumeRef = ref(storage, `uploads/${userId}/resume`);
      const url = await getDownloadURL(resumeRef);
      setResumeUrl(url);
    } catch (error) {
      console.error("No resume found for this user:", error);
      setResumeUrl(null);
    }
  };

  const handleResumeChange = async (e) => {
    const file = e.target.files[0];
    if (!file || !userId) return;

    const resumeRef = ref(storage, `uploads/${userId}/resume`);
    try {
      // Delete existing resume if any
      await deleteObject(resumeRef).catch(() => {});

      // Upload the new resume
      await uploadBytes(resumeRef, file);

      // Fetch the new resume URL
      const url = await getDownloadURL(resumeRef);
      setResumeUrl(url);

      // Notify parent component if needed
      if (onResumeChange) {
        onResumeChange(file);
      }
    } catch (error) {
      console.error("Error uploading resume:", error);
    }
  };

  return (
    <div className="file-handler">
      {/* Hidden file input */}
      <input
        type="file"
        id="file-upload"
        accept=".pdf,.doc,.docx"
        onChange={handleResumeChange}
        style={{ display: 'none' }}
      />

      <div className="file-handler-buttons">
        {/* Upload Resume Button */}
        <label htmlFor="file-upload" className="file-handler-button upload-button">
          Upload Resume
        </label>

        {/* Conditionally Render View Resume Text */}
        {resumeUrl && (
        <a
            href={resumeUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="view-resume-text"
        >
            <FaFilePdf className="file-icon" /> View
        </a>
        )}

      </div>
    </div>
  );
};

export default FileHandler;
