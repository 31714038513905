import React, { useState } from 'react';
import './AboutUs.css';
import { handleCheckout } from './checkoutUtils';

function LandingPage() {
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    if (loading) return; // Prevent multiple clicks while loading
    setLoading(true);
    try {
      await handleCheckout(); // Redirect to Stripe (this Promise resolves when redirection is initiated)
    } catch (error) {
      console.error('Error during checkout:', error);
      setLoading(false); // Stop loading if an error occurs
    }
    // No `setLoading(false)` here, as loading should persist until redirection
  };

  return (
    <div className="landing-container">
      {/* Hero Section */}
      <section className="hero">
        <h1 className="hero-title">Your AI-Powered Career Assistant</h1>
        <p className="hero-subtitle">
          Simplify your job application process with tailored resumes, cover letters, and interview preparation.
        </p>
        <button className="cta-button" onClick={handleButtonClick} disabled={loading}>
          {loading ? <div className="spinner"></div> : 'Subscribe Today'}
        </button>
      </section>

      {/* Features Section */}
      <section className="features">
        <h2 className="section-title">Why Choose PositionPrep?</h2>
        <ul className="features-list">
          <li><strong>AI-Driven Personalization:</strong> Customized insights for every application.</li>
          <li><strong>Save Time:</strong> Generate tailored cover letters and interview questions in seconds.</li>
          <li><strong>Be Prepared:</strong> Practice with targeted interview questions and answers.</li>
          <li><strong>Affordable and Flexible:</strong> Free to start, with premium options available.</li>
        </ul>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works">
        <h2 className="section-title">How It Works</h2>
        <div className="steps-container">
          <div className="step">
            <h3>1. Upload Your Resume</h3>
            <p>Keep an up-to-date resume on file.</p>
          </div>
          <div className="step">
            <h3>2. Paste the Job Description</h3>
            <p>Provide the PD for the job you're applying for.</p>
          </div>
          <div className="step">
            <h3>3. Get Tailored Insights</h3>
            <p>Receive interview questions and cover letter drafts.</p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <h2 className="section-title">What Our Users Say</h2>
        <p className="testimonial">
          "PositionPrep made landing my dream job so much easier. The tailored questions were spot on!" - Alex M., USA
        </p>
        <p className="testimonial">
          "As someone switching careers, I found PositionPrep invaluable. The cover letter generator saved me hours!" - Priya S., India
        </p>
        {/* Additional testimonials */}
      </section>

      {/* Pricing Section */}
      <section className="pricing">
        <h2 className="section-title">Start for Free</h2>
        <p>Unlock exclusive features and unlimited access to PositionPrep for just $19.49/month.</p>
        <button className="cta-button" onClick={handleButtonClick} disabled={loading}>
          {loading ? <div className="spinner"></div> : 'Upgrade to Plus'}
        </button>
      </section>
    </div>
  );
}

export default LandingPage;
