import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import FileUpload from './FileUpload';
import FilePreview from './FilePreview';
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { fetchResume } from './handleResume'; // Import reusable fetchResume function
import './Navbar.css';
import ProfileOrLogin from './ProfileOrLogin';
import FileHandler from './FileHandler';
import { handleCheckout } from './checkoutUtils';

const Navbar = () => {
  const [resumeFile, setResumeFile] = useState(null); // State to store resume file
  const [userId, setUserId] = useState(null); // Store the user ID
  const user = auth.currentUser;
  const [isExpanded, setIsExpanded] = useState(false);
  const [loadingUpgrade, setLoadingUpgrade] = useState(false); // Loading state for Upgrade button

  // Reset isExpanded when switching to desktop view
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isExpanded) {
        setIsExpanded(false);
      }
    };

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isExpanded]);

  // Fetch resume on user login
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        try {
          const resume = await fetchResume(user.uid);
          if (resume) {
            setResumeFile(resume);
          }
        } catch (error) {
          console.error("Error fetching resume:", error);
        }
      } else {
        setUserId(null);
        setResumeFile(null);
      }
    });

    return unsubscribe;
  }, []);

  const handleUpgradeClick = async () => {
    if (loadingUpgrade) return; // Prevent multiple clicks while loading
    setLoadingUpgrade(true);
    try {
      await handleCheckout(); // Redirect to the checkout process
    } catch (error) {
      console.error("Error initiating checkout:", error);
      setLoadingUpgrade(false); // Stop spinner on error
    }
    // Do not reset loading state here because the page will redirect
  };

  return (
    <nav className="navbar">
      <div className="navbar-row top-row">
        <Link to="/" className="brand">PositionPrep</Link>

        {/* File Upload and Preview Section */}
        <div className="file-upload-preview">
          <FileHandler />
        </div>
      </div>

      <div className={`navbar-row navLinks bottom-row ${isExpanded ? "expanded" : ""}`}>
        <Link to="/interview-questions" className="nav-text-link">Mock Interview Questions</Link>

        {/* Upgrade Button with Loading Spinner */}
        <button
          className="upgrade-button"
          onClick={handleUpgradeClick}
          disabled={loadingUpgrade}
        >
          {loadingUpgrade ? (
            <span>
              <div className="spinner"></div>
            </span>
          ) : (
            'Upgrade'
          )}
        </button>

        <div className="profile-link">
          <ProfileOrLogin user={user} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
