import React from 'react';
import './Footer.css'

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} PositionPrep. All rights reserved.</p>
      <div className="footer-links">
        <a href="/about" className="footer-link">About Us</a>
        <a
          href="https://positionprep.com/terms-of-service.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          Terms of Service
        </a>
        <a
          href="https://positionprep.com/privacy.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          Privacy
        </a>

      </div>
    </footer>
  );
}

export default Footer;
