import { ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import { storage } from "../firebase";

/**
 * Handles the upload and update of a resume file in Firebase Storage.
 * 
 * @param {Object} file - The file object to be uploaded.
 * @param {string} userId - The unique user ID.
 * @returns {Promise<string>} - Returns the URL of the uploaded resume or throws an error.
 */
export const handleResumeUpload = async (file, userId) => {
  if (!file || !userId) {
    throw new Error("File and userId are required for uploading a resume.");
  }

  try {
    // Reference to the user's resume in Firebase Storage
    const resumeRef = ref(storage, `uploads/${userId}/resume`);

    // Delete existing resume if any
    await deleteObject(resumeRef).catch(() => {
      // Ignore errors if no existing file
    });

    // Upload the new resume
    await uploadBytes(resumeRef, file);

    // Get the URL of the uploaded resume
    const url = await getDownloadURL(resumeRef);

    return url;
  } catch (error) {
    console.error("Error handling resume upload:", error);
    throw error;
  }
};

/**
 * Fetches the URL of the current uploaded resume from Firebase Storage.
 * 
 * @param {string} userId - The unique user ID.
 * @returns {Promise<Object>} - Returns an object containing the resume file details or null if not found.
 */
export const fetchResume = async (userId) => {
    if (!userId) {
      throw new Error("User ID is required to fetch the resume.");
    }
  
    try {
      const resumeRef = ref(storage, `uploads/${userId}/resume`);
      const url = await getDownloadURL(resumeRef);
      return { name: "Uploaded Resume", type: "application/pdf", url };
    } catch (error) {
      console.error("No resume found for this user:", error);
      return null; // Return null if no resume is found
    }
  };
