import React, { useState, useEffect } from 'react';
import { ref, uploadBytes, deleteObject, listAll, getDownloadURL } from "firebase/storage";
import { auth, storage, firestore } from "../firebase";
import { v4 as uuidv4 } from "uuid";
import { onAuthStateChanged } from "firebase/auth";
import './FileUpload.css'; // Make sure to create and import this CSS file for styling
import { useUser } from '../contexts/UserContext';
import { doc, setDoc } from "firebase/firestore";
import axios from 'axios';
import Modal from 'react-modal'; // React Modal

const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

const FileUpload = ({ onResumeChange }) => {
  const [userId, setUserId] = useState(sessionStorage.getItem("tempID") || uuidv4());
  const { user, onSignupOpen } = useUser();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        sessionStorage.removeItem("tempID");
      } else {
        sessionStorage.setItem("tempID", userId);
      }
    });
    return unsubscribe;
  }, [userId]);

  const openModal = (message) => {
    setModalContent(message);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleResumeChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    // Upload the file to Firebase and notify Navbar via onResumeChange
    await handleFileUpload(selectedFile, "resume");
    onResumeChange(selectedFile);  // Notify Navbar about the new resume file
  };

  const handleFileUpload = async (file, type) => {
    if (!user) {
      // If the user is not authenticated, open the signup modal and return
      onSignupOpen();
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      alert("Only PDF or Word documents are allowed.");
      return;
    }

    const userFolder = `uploads/${userId}/${type}`;
    const storageRef = ref(storage, userFolder);

    // Delete existing file if necessary
    if (type === "resume") {
      try {
        const list = await listAll(storageRef);
        for (const item of list.items) {
          await deleteObject(item);
        }
      } catch (error) {
        console.error("Error deleting existing file(s):", error);
      }
    }

    try {
      await uploadBytes(storageRef, file);
    } catch (error) {
      console.error("Error uploading file to Firebase Storage:", error);
      openModal("Error uploading the file. Please try again.");
      return;
    }
    
    try {
      // Parse the file
      const fileUrl = await getDownloadURL(storageRef);
      const parsedData = await parseFile(fileUrl, file.type);

      if (!parsedData) {
        openModal("Failed to parse the file.");
        return;
      }

      // Store parsed data in Firestore
      const userDocRef = doc(firestore, "uploads", userId, "parsedDocuments", "parsedResume");

      await setDoc(userDocRef, {
        type: type,
        content: parsedData,
        timestamp: new Date(),
      });

      openModal("File successfully uploaded, parsed, and stored.");
    } catch (error) {
      console.error("Error processing file:", error);
      openModal("An error occurred while uploading or parsing your file.");
    }
      // Create or update a Firestore document under the user's ID
      const userDocRef = doc(firestore, "users", userId, "settings", "resumeStatus");
  
      await setDoc(userDocRef, { resumeUploaded: true, timestamp: new Date() });
  };

  /**
   * Helper function to parse a file using the parsing logic
   * @param {string} fileUrl - The URL of the file to parse
   * @param {string} fileType - The MIME type of the file
   * @returns {Promise<string>} The parsed text content
   */
  const parseFile = async (fileUrl, fileType) => {
    try {
      const response = await axios.post("https://api-whiywkgf4q-uc.a.run.app/parseFile", { url: fileUrl });
      return response.data.text;
    } catch (error) {
      console.error("Error parsing file:", error.message);
      return null;
    }
  };

  return (
    <div className="file-upload">
      {/* Hidden file input */}
      <input
        type="file"
        id="file-upload"
        onChange={handleResumeChange}
        accept=".pdf,.doc,.docx" // Restrict to PDF and Word documents
        style={{ display: 'none' }}
      />
      
      {/* Custom label styled as a button */}
      <label htmlFor="file-upload" className="file-upload-label">
        Upload Resume
      </label>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Notification"
        className="modal"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div>
          <p>{modalContent}</p>
          <button onClick={closeModal} className="modal-close-button">
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default FileUpload;
