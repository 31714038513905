import React, { useState } from 'react';
import './PaymentModal.css'; // Optional CSS for styling
import { handleCheckout } from './checkoutUtils';

const PaymentModal = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    if (loading) return; // Prevent multiple clicks while loading
    setLoading(true);
    try {
      await handleCheckout(); // Redirects to Stripe
    } catch (error) {
      console.error('Error during checkout:', error);
      setLoading(false); // Stop loading if an error occurs
    }
    // No `setLoading(false)` here, as loading should persist until redirection
  };

  return (
    isOpen && (
      <div className="modal-overlay" onClick={onClose}>
        <div className="payment-modal" onClick={(e) => e.stopPropagation()}>
          <button className="close-button" onClick={onClose}>×</button>
          <h2 className="payment-title">Subscribe to Premium</h2>
          <p>Get access to unlimited access by subscribing to PositionPrep.</p>
          <button
            onClick={handleButtonClick}
            disabled={loading}
            className="payment-button"
          >
            {loading ? (
              <span>
                <div className="spinner"></div>
              </span>
            ) : (
              'Subscribe Now'
            )}
          </button>
        </div>
      </div>
    )
  );
};

export default PaymentModal;
